import { DateTime } from 'luxon';

class FakeMomentTimezone {
  constructor() {
    this._ = DateTime.now();
  }
  
  tz(timestampMs, timezone) {
    const _ms = timestampMs ? +timestampMs : NaN;
    const _options = timezone ? { zone: timezone } : undefined;
    this._ = !isNaN(_ms) ? DateTime.fromMillis(_ms, _options) : DateTime.now();
    return this;
  }

  utcOffset() {
    return this._.offset;
  }
}

export default FakeMomentTimezone;