<script>
import { Chart } from 'highcharts-vue/src';
import Highcharts from 'highcharts/highcharts';
import loadExporting from 'highcharts/modules/exporting';
import loadExportingOffline from 'highcharts/modules/offline-exporting';
import loadExportData from 'highcharts/modules/export-data';
import loadNoData from 'highcharts/modules/no-data-to-display';
import ResizeObserver from 'resize-observer-polyfill';

import FakeMomentTimezone from '@/helpers/FakeMomentTimezone.js';

window.moment = new FakeMomentTimezone();
loadExporting(Highcharts);
loadExportingOffline(Highcharts);
loadExportData(Highcharts);
loadNoData(Highcharts);

const throttled = (fn) => {
  let ticking = false;
  return () => {
    if (!ticking) {
      ticking = true;
      window.requestAnimationFrame(() => {
        ticking = false;
        fn();
      });
    }
  };
};

Highcharts.setOptions({
  credits: {
    enabled: false
  },
  exporting: {
    fallbackToExportServer: false,
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen'
        ]
      }
    }
  },
  time: { useUTC: false },
  global: { useUTC: false }
});

export default {
  extends: Chart,
  props: {
    loading: Boolean
  },
  methods: {
    onResize() {
      this.$emit('resize');
      if (this.chart && this.chart.options) {
        this.chart.reflow();
      }
    },
    handleLoading() {
      if (!this.loading) this.chart.hideLoading();
      else this.chart.showLoading();
    }
  },
  mounted() {
    const events = { redraw: () => this.$emit('render') };
    this.options.chart = this.options.chart ? Object.assign(this.options.chart, events) : events;
    Chart.mounted.call(this);
    this.$el.className += ' highcharts-container';
    const resizeObserver = new ResizeObserver(throttled(() => this.onResize()));
    resizeObserver.observe(this.$el);
  },
  watch: {
    loading: {
      immediate: true,
      handler() {
        if (this.chart) this.handleLoading()
        else this.$nextTick(() => this.handleLoading());
      }
    },
    'options.series': {
      handler(series) {
        // When setting the series to an empty array, the no data text is not displayed unless a redraw is triggered
        if (!series.length) this.$nextTick(() => this.chart.redraw());
      }
    }
  }
};
</script>

<style scoped>
.highcharts-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;
}
</style>