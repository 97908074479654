export default {
  props: {
    timezone: String
  },
  data() {
    return {
      options: {
        time: {
          timezone: 'UTC'
        }
      }
    };
  },
  watch: {
    timezone: {
      immediate: true,
      handler() {
        if (this.timezone) this.$set(this.options.time, 'timezone', this.timezone);
        else this.$set(this.options.time, 'timezone', 'UTC');
      }
    }
  }
};