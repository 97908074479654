<template>
  <b-container fluid="lg" class="p-1 p-sm-2 p-md-3 dashboard-container d-flex flex-column">
    <b-card
      header="Blind Controls"
      body-class="p-2 p-sm-3 p-lg-4"
      header-class="p-2 p-md-3"
      class="mb-1 mb-sm-2 mb-md-3">

      <blind-controls />
    </b-card>

    <b-card
      body-class="p-2 p-md-3 p-lg-4 d-flex flex-column flex-grow-1 min-height-fit"
      class="mb-0 d-flex flex-column flex-grow-1 min-height-fit">

      <daily-summary />
    </b-card>
  </b-container>
</template>

<script>
import { BContainer, BCard } from 'bootstrap-vue';

import DailySummary from '@/components/DailySummary.vue';
import BlindControls from '@/components/BlindControls.vue';

export default {
  name: 'Dashboard',
  components: {
    BContainer,
    BCard,
    DailySummary,
    BlindControls
  }
};
</script>

<style scoped>
.dashboard-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>