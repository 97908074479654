<template>
  <div>
    <b-form-select
      v-model="sensor"
      :options="sensorOptions"
      size="sm"
      class="select"
      v-if="sensorOptions.length > 2" />

    <high-charts-responsive
      :options="options"
      :loading="loading"
      ref="graph"
      class="highcharts-container" />
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue';

import HighChartsResponsive from '@/components/HighChartsResponsive.vue';
import SparklineMixin from '@/components/graphs/SparklineMixin';
import TimezoneMixin from '@/components/graphs/TimezoneMixin';
import { extractIVData } from '@/helpers/graph-helpers';

export default {
  name: 'AcConsumptionGraph',
  mixins: [SparklineMixin, TimezoneMixin],
  components: {
    BFormSelect,
    HighChartsResponsive
  },
  props: {
    sensors: {
      type: Array
    },
    config: {
      type: Object
    }
  },
  data() {
    return {
      sensor: null,
      loading: false,
      options: {
        chart: {
          marginTop: 32
        },
        yAxis: {
          title: {
            text: 'AC Consumption'
          },
          labels: {
            format: '{text} W'
          }
        },
        tooltip: {
          valueSuffix: ' W'
        },
        xAxis: {
          min: null,
          max: null
        },
        series: []
      }
    };
  },
  computed: {
    sensorOptions() {
      const options = this.sensors.filter(s => s.dataSpec['P'] && process.env.VUE_APP_EPWC_AC_SENSOR === s.uuid).map(s => ({ value: s, text: s.name }));
      options.unshift({ value: null, text: 'Select a Sensor', disabled: true });
      return options;
    },
  },
  methods: {
    async plot() {
      const { sensor } = this;
      const { from, to } = this.config;
      if (!sensor || !from || !to) return;

      try {
        this.setLoading(true);
        const query = { sensorUuid: sensor.uuid, parameters: 'P', from, to };
        const data = await this.$epwcApi.get(`/sites/${sensor.siteCode}/sensor-data`, { query });
        if (data.length) this.$set(this.options, 'series', [{ name: 'AC Consumption', data: extractIVData(data)['P'] || [], color: '#a05bf4' }]);
        else this.$set(this.options, 'series', []);
        
        this.$set(this.options.xAxis, 'min', (new Date(from)).valueOf());
        this.$set(this.options.xAxis, 'max', (new Date(to)).valueOf());
      } finally {
        this.setLoading(false);
      }
    },
    setLoading(loading) {
      this.loading = loading;
      this.$emit('loading', this.loading);
    }
  },
  watch: {
    config: {
      immediate: true,
      handler() {
        if (this.config) this.plot();
      }
    },
    sensorOptions: {
      immediate: true,
      handler() {
        if (this.sensorOptions.length > 1) this.sensor = this.sensorOptions[1].value;
      }
    },
    sensor: {
      immediate: true,
      handler() {
        if (this.sensor) this.plot();
      }
    }
  }
};
</script>

<style scoped="true">
.select {
  max-width: 200px;
  position: absolute;
  z-index: 1;
  right: 60px;
}
</style>