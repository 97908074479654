<template>
  <div>
    <b-form-radio-group
      v-model="duration"
      :options="durationOptions"
      size="sm"
      :disabled="requestInProgress"
      class="mb-3 d-flex flex-row justify-content-center justify-content-md-start" />

    <b-row class="d-flex flex-row justify-content-center justify-content-md-start m-0">
      <b-col cols="3" sm="4" md="3" class="px-1">
        <div class="d-flex flex-column">
          <b-button
            variant="outline-primary"
            size="sm"
            :disabled="requestInProgress"
            class="mb-2"
            :pressed="status === 'open'"
            @click="makeControlsRequest('open')">
            RAISE
          </b-button>

          <b-button
            variant="outline-primary"
            size="sm"
            :disabled="requestInProgress"
            :pressed="status === 'horizontal'"
            @click="makeControlsRequest('horizontal')">
            OPEN
          </b-button>
        </div>
      </b-col>

      <b-col cols="3" sm="4" md="3" class="px-1">
        <div class="d-flex flex-column">
          <b-button
            variant="outline-primary"
            size="sm"
            :disabled="requestInProgress"
            class="mb-2"
            :pressed="status === 'closed'"
            @click="makeControlsRequest('closed')">
            LOWER
          </b-button>

          <b-button
            variant="outline-primary"
            size="sm"
            :disabled="requestInProgress"
            :pressed="status === 'vertical'"
            @click="makeControlsRequest('vertical')">
            CLOSE
          </b-button>
        </div>
      </b-col>

      <b-col cols="4" sm="3" md="2" class="px-1 d-flex flex-column">
        <b-button
          variant="outline-primary"
          size="sm"
          :disabled="requestInProgress"
          class="flex-grow-1"
          :pressed="status === 'track'"
          @click="makeControlsRequest('track')">
          RESUME TRACKING
        </b-button>
      </b-col>
    </b-row>

    <b-row class="d-flex flex-row justify-content-center justify-content-md-start my-3 mx-0">
      <b-col cols="12" sm="12" md="8" class="px-1 d-flex align-items-center">
        <div class="flex-grow-1 px-3">
          <slider
            v-model="tilt"
            data-label="label"
            data-value="value"
            :data="tiltOptions"
            :disabled="requestInProgress"
            :marks="tiltMarks"
            lazy
            @change="makePositionOverrideRequest" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BFormRadioGroup } from 'bootstrap-vue';
import { DateTime } from 'luxon';
import Slider from 'vue-slider-component';

function resolveStateFromStatus(mode, position) {
  if (mode === 'track') return { status: 'track', tilt: null };
  else if (mode === 'open') return { status: 'open', tilt: null };
  else if (mode === 'closed') return { status: 'closed', tilt: null }
  else if (mode === 'horizontal' || (mode === 'position_override' && position === 0)) return { status: 'horizontal', tilt: 0 };
  else if (mode === 'vertical' || (mode === 'position_override' && position === 100)) return { status: 'vertical', tilt: 100 };
  else if (mode === 'position_override') return { status: null, tilt: position };
  return { status: null, tilt: null };
}

export default {
  name: "BlindControls",
  components: {
    BRow,
    BCol,
    BButton,
    BFormRadioGroup,
    Slider,
  },
  data() {
    return {
      duration: 30,
      tilt: null,
      status: null,
      requestInProgress: false,
      durationOptions: [
        { text: '30 mins', value: 30 },
        { text: '1 hr', value: 60 },
        { text: '6 hrs', value: 360 },
        { text: '1 day', value: 1440 }
      ],
      tiltOptions: [
        { label: 'Opened', value: 0 },
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '30', value: 30 },
        { label: '40', value: 40 },
        { label: '50', value: 50 },
        { label: '60', value: 60 },
        { label: '70', value: 70 },
        { label: '80', value: 80 },
        { label: '90', value: 90 },
        { label: 'Closed', value: 100 },
      ],
      tiltMarks: (val) => {
        if (val % 20 !== 0) return { label: '' };
        if (val === 0) return { label: 'Opened' };
        else if (val === 100) return { label: 'Closed' };
        return { label: val };
      }
    };
  },
  methods: {
    async makeControlsRequest(mode) {
      if (this.duration == null) return;
      try {
        const state = resolveStateFromStatus(mode);
        this.status = state.status;
        this.tilt = state.tilt;
        this.requestInProgress = true;
        const start = DateTime.now();
        const end = start.plus({ minutes: this.duration });
        const query = {
          time_start: start.toISO(),
          time_end: end.toISO(),
          blind_name: 'Blind01',
          mode
        };

        await this.$controlsApi.post('/set_mode', { query });
      } catch(e) {
        this.$bvToast.toast('The request has failed. Please try again.', {
          title: 'Error',
          variant: 'danger',
          solid: true
        });

        this.status = null;
        this.tilt = null;
        this.getBlindStatus();

        if (e.name !== 'ApiError' || e.status >= 500) throw e;
      } finally {
        this.requestInProgress = false;
      }
    },
    async makePositionOverrideRequest(position) {
      if (this.duration == null || position == null) return;
      try {
        const state = resolveStateFromStatus('position_override', position);
        this.status = state.status;
        this.tilt = state.tilt;
        this.requestInProgress = true;
        const start = DateTime.now();
        const end = start.plus({ minutes: this.duration });
        const query = {
          time_start: start.toISO(),
          time_end: end.toISO(),
          blind_name: 'Blind01',
          position: Math.floor((position / 100) * 60)
        };

        await this.$controlsApi.post('/position_override', { query });
      } catch(e) {
        this.$bvToast.toast('The request has failed. Please try again.', {
          title: 'Error',
          variant: 'danger',
          solid: true
        });

        this.status = null;
        this.tilt = null;
        this.getBlindStatus();

        if (e.name !== 'ApiError' || e.status >= 500) throw e;
      } finally {
        this.requestInProgress = false;
      }
    },
    async getBlindStatus() {
      try {
        const response = await this.$controlsApi.get('/blind_mode', { query: { blind_name: 'Blind01' } });
        const { mode, position } = response;

        let uiPosition = null;
        if (position != null) {
          if (Array.isArray(position)) uiPosition = Math.floor((position[0] / 60) * 100);
          else uiPosition = Math.floor((position / 60) * 100);
        }

        const state = resolveStateFromStatus(mode, uiPosition);
        this.status = state.status;
        this.tilt = state.tilt;
      } catch (e) {
        if (e.name !== 'ApiError' || e.status >= 500) throw e;
      }
    }
  },
  created() {
    this.getBlindStatus();
    setInterval(this.getBlindStatus, 5 * 60000);
  }
};
</script>
