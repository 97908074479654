export default {
  props: {
    zoomExtremes: Array,
    showLegend: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let self = this;
    return {
      options: {
        chart: {
          type: 'line',
          zoomType: 'x',
          borderWidth: 0,
          style: {
            overflow: 'visible'
          },
          skipClone: true,
          resetZoomButton: {
            theme: {
              style: {
                display: 'none'
              }
            }
          },
          spacingLeft: 0,
          spacingTop: 15
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: ''
          },
          startOnTick: true,
          endOnTick: true,
          events: {
            afterSetExtremes(event) {
              if (event.trigger === 'zoom') {
                const { min, max } = event;
                self.$emit('zoom', [min, max]);
              }
            }
          }
        },
        yAxis: {
          title: {
            style: {
              fontSize: 14,
              textOverflow: 'ellipsis'
            }
          },
          startOnTick: true,
          endOnTick: true,
          tickAmount: 3,
          labels : {
            align: 'left',
            x: 0,
            y: -2,
            style: {
              fontSize: 13
            }
          }
        },
        legend: {
          enabled: false,
          floating: true,
          y: 20,
          itemDistance: 10
        },
        tooltip: {
          hideDelay: 0
        },
        exporting: {
          buttons: {
            contextButton: {
              x: 0,
              y: -12,
              menuClassName: 'highcharts-contextmenu msi-highcharts-contextmenu'
            }
          }
        }
      }
    }
  },
  watch: {
    zoomExtremes() {
      if (this.$refs.graph && this.$refs.graph.chart) {
        if (this.$refs.graph.chart.xAxis[0] && this.zoomExtremes) {
          const [min, max] = this.zoomExtremes;
          const { min: axisMin, max: axisMax } = this.$refs.graph.chart.xAxis[0];
          if (min !== axisMin || max !== axisMax) this.$refs.graph.chart.xAxis[0].setExtremes(min, max);
        }
      }
    },
    showLegend: {
      immediate: true,
      handler() {
        this.$set(this.options.legend, 'enabled', !!this.showLegend);
      }
    }
  }
};