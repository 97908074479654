<template>
  <div class="flex-grow-1 d-flex flex-column min-height-fit">
    <div class="d-flex align-items-center justify-content-between pb-3">
      <div>
        <date-select
          v-if="now"
          v-model="date"
          :maxDate="now" />
      </div>

      <div class="d-flex align-items-center">
        <div
          v-if="now === date"
          @click="setConfig"
          class="mr-2">
          
          <CIcon
            name="cilLoopCircular"
            v-b-tooltip="'Refresh Data'"
            class="action-icon action-icon-lg" />
        </div>

        <div @click="handleZoom([null, null])">
          <reset-zoom-icon
            v-b-tooltip="'Reset Zoom'"
            class="action-icon mt-1"
            style="width: 1.6rem; height: 1.6rem;" />
        </div>
      </div>
    </div>

    <div class="d-flex flex-column flex-grow-1 min-height-fit">
      <power-graph
        :config="config"
        :modules="solarModules"
        :zoomExtremes="zoomExtremes"
        :timezone="timezone"
        @zoom="handleZoom"
        class="graph-container flex-grow-1 flex-shrink-1 d-flex flex-column" />

      <ac-consumption-graph
        :config="config"
        :sensors="epwcSensors"
        :zoomExtremes="zoomExtremes"
        :timezone="timezone"
        @zoom="handleZoom"
        class="graph-container flex-grow-1 flex-shrink-1 d-flex flex-column" />
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { DateTime } from 'luxon';
import { CIcon } from '@coreui/vue';

import DateSelect from '@/components/DateSelect.vue'
import PowerGraph from '@/components/graphs/PowerGraph.vue';
import AcConsumptionGraph from '@/components/graphs/AcConsumptionGraph.vue';

import ResetZoomIcon from '../../public/reset-zoom-icon.svg';

export default {
  name: 'DailySummary',
  components: {
    CIcon,
    DateSelect,
    PowerGraph,
    AcConsumptionGraph,
    ResetZoomIcon
  },
  data() {
    return {
      date: null,
      now: null,
      config: null,
      zoomExtremes: null
    };
  },
  computed: {
    site: get('site/site'),
    solarModules: get('solarmodules/solarModules'),
    epwcSensors: get('sensors/epwcSensors'),
    timezone() {
      return this.site && this.site.timezone ? this.site.timezone : null;
    }
  },
  methods: {
    setConfig() {
      if (this.date) {
        const date = DateTime.fromSQL(this.date, { zone: this.site.timezone });
        this.config = { from: date.startOf('day').toISO(), to: date.endOf('day').toISO() };
      }
    },
    handleZoom(zoomExtremes) {
      this.zoomExtremes = zoomExtremes;
    }
  },
  watch: {
    site: {
      immediate: true,
      handler() {
        if (this.site && this.site.timezone) {
          this.date = DateTime.now().setZone(this.site.timezone).toSQLDate();
          this.now = this.date;
        }
      }
    },
    date: {
      immediate: true,
      handler() {
        this.setConfig();
        this.handleZoom([null, null]);
      }
    }
  }
};
</script>