<template>
  <flat-pickr
    :value="value"
    :placeholder="placeholder"
    :config="config"
    @input="handleInput"
    class="msi-date-select msi-date-select-sm" />
</template>

<script>
import flatPickr from 'vue-flatpickr-component';

export default {
  name: 'DateSelect',
  components: {
    flatPickr
  },
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String,
      default: 'Date'
    },
    disableMobile: {
      type: Boolean,
      default: false
    },
    allowInput: {
      type: Boolean,
      default: false
    },
    maxDate: {
      type: String,
      default: null
    }
  },
  computed: {
    config() {
      return {
        disableMobile: this.disableMobile,
        allowInput: this.allowInput,
        maxDate: this.maxDate,
        dateFormat: 'Y-m-d'
      };
    }
  },
  methods: {
    handleInput(date) {
      this.$emit('input', date);
    }
  }
}
</script>

<style>
  .msi-date-select {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid;
    color: #768192;
    background-color: #fff;
    border-color: #d8dbe0;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }

  .msi-date-select:focus {
    color: #768192;
    background-color: #fff;
    border-color: #fabc90;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgb(244 114 22 / 25%);
    box-shadow: 0 0 0 0.2rem rgb(244 114 22 / 25%);
  }

  .msi-date-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
</style>