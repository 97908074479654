function extractEnergy(data, uuidsToInclude) {
  data = data.map(d => ({ ...d, timestamp: (new Date(d.timestamp)).valueOf() }));
  data.sort((a, b) => a.timestamp - b.timestamp);

  let lastPoint = null;
  return data.reduce((acc, cur) => {
    if (
      !uuidsToInclude ||
      (uuidsToInclude && uuidsToInclude.includes(cur.uuid))
    ) {
      if (!lastPoint || (lastPoint && lastPoint.timestamp !== cur.timestamp)) {
        acc.push({
          total: cur.energy,
          timestamp: cur.timestamp,
          breakdown: { [cur.uuid]: cur.energy },
        });
      } else {
        const energy = acc[acc.length - 1];
        energy.total += cur.energy;
        energy.breakdown[cur.uuid] = cur.energy;
      }
    }

    lastPoint = cur;
    return acc;
  }, []);
}

function extractIVData(data, gap = 1.2e6) {
  if (!Array.isArray(data)) return {};
  const previousTimestamp = {};

  return data.reduce((acc, cur) => {
    cur.timestamp = (new Date(cur.timestamp)).getTime();
    Object.keys(cur.data).forEach((type) => {
      if (cur.data[type] != null) {
        let discontinuous = false;
        if (previousTimestamp[type] && (cur.timestamp - previousTimestamp[type] > gap)) discontinuous = true;
        previousTimestamp[type] = cur.timestamp;

        if (discontinuous) acc[type].push([cur.timestamp, null]);
        if (acc[type]) acc[type].push([cur.timestamp, cur.data[type]]);
        else acc[type] = [[cur.timestamp, cur.data[type]]];
      }
    });

    return acc;
  }, {});
}

function formatValueUnit(value, unit, sigDigits = 2) {
  if (value / 1000000000 >= 1) return `${parseFloat((value / 1000000000).toPrecision(sigDigits))} G${unit}`;
  if (value / 1000000 >= 1) return `${parseFloat((value / 1000000).toPrecision(sigDigits))} M${unit}`;
  if (value / 1000 >= 1) return `${parseFloat((value / 1000).toPrecision(sigDigits))} k${unit}`;
  return `${parseFloat(value.toPrecision(sigDigits))} ${unit}`;
}

function parseEnergy(energy, sigDigits = 2) {
  return formatValueUnit(energy, 'Wh', sigDigits);
}

function parsePower(power, sigDigits = 2) {
  return formatValueUnit(power, 'W', sigDigits);
}

export { 
  extractEnergy,
  parseEnergy,
  extractIVData,
  parsePower
};