<template>
  <div>
    <b-form-select
      v-model="module"
      :options="moduleOptions"
      size="sm"
      class="select"
      v-if="moduleOptions.length > 2" />

    <high-charts-responsive
      :options="options"
      :loading="loading"
      ref="graph"
      class="highcharts-container" />
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue';

import HighChartsResponsive from '@/components/HighChartsResponsive.vue';
import SparklineMixin from '@/components/graphs/SparklineMixin';
import TimezoneMixin from '@/components/graphs/TimezoneMixin';
import { extractIVData } from '@/helpers/graph-helpers';

export default {
  name: 'PowerGraph',
  mixins: [SparklineMixin, TimezoneMixin],
  components: {
    BFormSelect,
    HighChartsResponsive
  },
  props: {
    modules: {
      type: Array
    },
    config: {
      type: Object
    }
  },
  data() {
    return {
      module: null,
      loading: false,
      options: {
        chart: {
          marginTop: 32
        },
        yAxis: {
          title: {
            text: 'Max Power'
          },
          labels: {
            format: '{value} W'
          }
        },
        tooltip: {
          valueSuffix: ' W'
        },
        xAxis: {
          min: null,
          max: null
        },
        series: []
      }
    };
  },
  computed: {
    moduleOptions() {
      const options = this.modules.map(m => ({ value: m, text: m.name }));
      options.unshift({ value: null, text: 'Select a Blind', disabled: true });
      return options;
    }
  },
  methods: {
    async plot() {
      const { module } = this;
      const { from, to } = this.config;
      if (!module || !from || !to) return;

      try {
        this.setLoading(true);
        const query = { moduleUuid: module.uuid, parameters: 'Pmp', from, to };
        const data = await this.$epwcApi.get(`/sites/${module.siteCode}/iv/parameters`, { query });
        if (data.length) this.$set(this.options, 'series', [{ name: 'Max Power', data: extractIVData(data)['Pmp'] || [], color: '#50b2c8' }]);
        else this.$set(this.options, 'series', []);
        
        this.$set(this.options.xAxis, 'min', (new Date(from)).valueOf());
        this.$set(this.options.xAxis, 'max', (new Date(to)).valueOf());
      } finally {
        this.setLoading(false);
      }
    },
    setLoading(loading) {
      this.loading = loading;
      this.$emit('loading', this.loading);
    }
  },
  watch: {
    config: {
      immediate: true,
      handler() {
        if (this.config) this.plot();
      }
    },
    moduleOptions: {
      immediate: true,
      handler() {
        if (this.moduleOptions.length > 1) this.module = this.moduleOptions[1].value;
      }
    },
    module: {
      immediate: true,
      handler() {
        if (this.module) this.plot();
      }
    }
  }
};
</script>

<style scoped="true">
.select {
  max-width: 200px;
  position: absolute;
  z-index: 1;
  right: 60px;
}
</style>